<template>
	<div>
		<headers :userList="userinfor"></headers>
		<div class="box">
			<h2>保证金支付</h2>
			<p class="money">￥{{money}}</p>
			<div class="content">
				<div class="yue">
					<button @click="gopay">去支付</button>
				</div>
			</div>
			<div class="bottom">
				<p>支付方式</p>
				<div class="paylist" ref="paymethod">
					<div class="method" :class="{active:type==index}" v-for="(item,index) of paylist" :key="index" @click="selectmethod(item.type,index)">
						<img :src="item.img" alt="">
						<span>{{item.name}}</span>
					</div>
				</div>
			</div>
		</div>
		<publicBottom></publicBottom>
	</div>
</template>

<script>
	import headers from "./header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部
	import {
		usergetinfo
	} from "@/request/user";
	export default {
		name: "paymoney",
		data() {
			return {
				userinfor: "",
				money: "",
				type:-1,
				paytype:"",
				out_trade_no:"",
				paylist: [{
						type: "wechatScan",
						img: require('@/assets/image/Cashier_wixin_icon@2x.png'),
						name: "微信支付"
					},
					{
						type: "alipayScan",
						img:require('@/assets/image/Cashier_zhifubao_icon@2x.png'),
						name: "支付宝"
					},
					{
						type: "bankpay",
						img:require('@/assets/image/Cashier_Bank_icon@2x.png'),
						name: "银联支付"
					},
					{
						type: "balance",
						img: require('@/assets/image/Cashier_wallet_icon@2x.png'),
						name: "余额支付"
					},
				],
			}
		},
		components: {
			headers,
			publicBottom
		},
		created() {
			//   个人信息
			usergetinfo({
				token: this.$token
			}).then(res => {
				if (res.code == 1) {
					this.userinfor = res.data;
				}
			});
			// 获取编号,以几要交多少钱
			this.getpayinfor();
		},
		mounted() {

		},
		methods: {
			selectmethod(type,index){
				this.paytype=type;
				this.type=index;
			},
			getpayinfor(){
				this.$axios.post("/api/serviceprovider/paybaozhenjin",{
					token:this.$token
				}).then(res=>{
					if(res.data.code==1){
						this.money=res.data.data.money;
						this.out_trade_no=res.data.data.out_trade_no;
					}
				})
			},
			gopay(){
				this.$router.push({name:"支付",query:{money:this.money,out_trade_no:this.out_trade_no,pay_type:this.paytype}})
			}
		}
	}
</script>

<style lang="less" scoped>
	.box {
		width: 700px;
		height: 500px;
		margin: 20px auto;
		box-shadow: 0px 0px 10px 5px #EAEAEA;
		padding: 20px;
		position: relative;

		h2 {
			text-align: center;
			border-bottom: 1px solid #EAEAEA;
			padding-bottom: 10px;
		}

		.money {
			text-align: center;
			font-size: 50px;
			color: red;
			margin-top: 20px;
		}

		.bottom {
			position: absolute;
			bottom: 50px;
			width: 94%;
			padding: 10px 0;

			p {
				margin: 10px;
				border-bottom: 1px solid #EAEAEA;
				padding: 10px 0;
			}

			.paylist {
				.method {
					display: inline-block;
					padding: 5px 10px;
					border: 1px solid #EAEAEA;
					margin-right: 20px;
					cursor: pointer;
					img{
						width: 20px;
						position: relative;
						top: 4px;
						left: -5px;
					}
				}
				.active{
					color:#4EEEC8;
					border:2px solid #4EEEC8;
				}
			}

		}
	}

	.content {
		width: 200px;
		height: 200px;
		margin: 0 auto;

		.yue {
			button {
				display: block;
				padding: 10px 30px;
				margin: 40px auto;
				margin-top: 100px;
			}
		}
	}
</style>
